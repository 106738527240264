import { Amplify, Auth } from 'aws-amplify';
import jwt_decode from 'jwt-decode';
import { saveErrorToDB } from './handleBackendErrors';

Amplify.configure({
  Auth: {
    region: 'ap-south-1',
    userPoolId: `${process.env.REACT_APP_DOCTORS_FLOW_SIGNUP_USERPOOLID}`,
    userPoolWebClientId: `${process.env.REACT_APP_DOCTORS_FLOW_SIGNUP_CLIENTID}`,
  },
});

export const QUERY_LISTPROFILES = {
  query: `query MyQuery {
        getProfile {
            userID
            name
            phone
            email
            newsletter
            cityWithState
            phoneVerified
            emailVerified
            exp
            expMonths
            salary
            activelySearching
            profilePicURL
            workStatus
            locationID
            isCurrentlyStduying
            isOutsideIndia
            linkedInURL
            getLocationOutsideIndia {
                area
                country
                loiID
              }
        }
      }`,
};

export const QUERY_GETRESUME = {
  query: `query MyQuery {
        getResume {
            filename
            headline
            uploadedAt
            url
            videoURL
        }
    }`,
};

export const QUERY_GETRESUMEHEADLINE = {
  query: `query MyQuery {
        getResume {
            headline
        }
    }`,
};

export const GET_CITY = {
    query: `query MyQuery {
      getCityMaster {
        city
        cityWithState 
        lmID 
      }
    }`,
  };
  

export  const GET_QUALIFICATIONS = {
    query: `query MyQuery {
      getQualification(industry: "Other") {
        qualification
        emID
      }
    }
  `,
    variables: null,
    operationName: "MyQuery",
  };

export   const GET_SKILL_MASTER = {
    query: `query MyQuery {
        getSkillMaster {
        name
        skillID
      }
    }`,
    variables: null,
    operationName: "MyQuery",
  };

export const QUERY_GET_RESUME_HEADLINE_SUGGESTION = {
  query: `query MyQueryCopy {
        getResumeHeadlineSuggestion
      }`,
};

export const QUERY_GETSKILLSLIST = {
  query: `query MyQuery {
        getSkillsList  {          
            name
        }
    }`,
};

export const GET_INSTITUTE_MASTER = {
    query: `query MyQuery {
      getHealthInstituteMaster {
        himID
        name
      }
    }
  `,
    variables: null,
    operationName: "MyQuery",
  };


export const QUERY_GETEDUCATIONLIST = {
  query: `query MyQuery {
        getEducationList   {          
            courseName
        }
    }`,
};

export const QUERY_GETEXPERIENCE = {
  query: `query MyQuery {
        getExperienceList    {          
            hospital
        }
    }`,
};
export const QUERY_GETMEMBERSHIPS = {
  query: `query MyQuery {
        getMemberships     {          
            organization
        }
    }`,
};

export const QUERY_GETPERSONALDETAILS = {
  query: `query MyQuery {
        getPersonalDetails     {          
            gender
        }
    }`,
};

export const QUERY_GETCAREERPROFILEPERCENTAGE = {
  query: `query MyQuery {
        getCareerProfile     {          
            departmentName
        }
    }`,
};

export const QUERY_COURSEID = {
  query: `query MyQuery {
        getCourseMaster {
            cmID
            name
        }
    }`,
};

export const QUERY_SPECIALIZATIONID = {
  query: `query MyQuery {
        getSpecializationMaster  {
            name
            smID
        }
    }`,
};

export const QUERY_UNIVERSITYID = {
  query: `query MyQuery {
        getUniversityMaster   {
            name 
            umID
        }
    }`,
};

export const GET_INDUSTRY={
    query: `query MyQuery {
        getHCIIndustry {
          hciID
          industry
          specialty
        }
      }`,
      variables: null,
      operationName: "MyQuery",
}


export const QUERY_GETPROFILECOURSEMASTER = {
  query: `query MyQuery {
        getProfileCourseMaster {
          name
          pcmID
        }
      }`,
};

export const QUERY_GETEDUCATION = {
  query: `query MyQuery {
        getEducationList {
            course
            courseType
            eduID
            emID
            healthcareIndustry
            specialization
            qualification
            university
            universityID
            yearOfPassing
            isOtherUniversity
        }
    }`,
};

export const QUERY_GET_CARD_ELIGIBLE_STATUS = {
  query: `query MyQuery {
        getCardEligibleStatus {
          isEligible
          isCardCreated
        }
      }`,
};

export const QUERY_SAVED_SKILL = {
  query: `query MyQuery {
        getSkillsList {
            name
            smID
           }
        }`,
};

export const QUERY_DESIGNMASTER = {
  query: `query MyQuery {
        getDesignationMaster {
            dmID 
            name
        }
    }`,
};

export const QUERY_HOSPITALMASTER = {
  query: `query MyQuery {
        getHospitalMaster {
            hmID 
            name
        }
    }`,
};

export const QUERY_NOTICEMASTER = {
  query: `query MyQuery {
        getNoticePeriodMasters {
            npID 
            notice
        }
    }`,
};

export const QUERY_GETEXPERIENCELIST = {
  query: `query MyQuery {
        getExperienceList {
            currentlyWorking
            description
            designation
            designationID
            employmentType
            expID
            healthInstituteID
            healthInstituteTypeID 
            instituteName
            instituteType
            jobType
            noticePeriodID
            startingMonth
            startingYear
            workingMonth
            workingYear
            isOtherSkill
            isOtherIndustry
            isOtherInstitute
            lastWorkingDay
            departmentID
        }
    }`,
};

export const QUERY_GETMEMBERSHIP = {
  query: `query MyQuery {
         getMemberships {
            lifeMembership
            memID
            organization
            positionHeld
        }
    }`,
};

export const QUERY_GETPAPERS = {
  query: `query MyQuery {
         getPapers {
            description
            fileURL
            month
            paperID
            title
            url
            year 
            fileName
        }
    }`,
};

export const QUERY_GETAWARDS = {
  query: `query MyQuery {
         getAwards {
            awardID
            description
            month
            name
            url
            year             
        }
    }`,
};

export const QUERY_PERSONAL_DETAILS = {
  query: `query MyQuery {
        getPersonalDetails {
            bothAddressSame
            dateofBirth
            differentlyAbled
            gender
            maritalStatus
            pdID
            permanentAddressL1
            permanentAddressL2
            permanentCity
            permanentCountry
            permanentLocationID
            permanentState
            permanentZip
            personalInterest
            presentAddressL1
            presentAddressL2
            presentCity
            presentCountry
            presentLocationID
            presentState
            presentZip
            professionalInterest
            spouseName
            spouseOccupation
            IsOtherPermanentCity
            IsOtherPresentCity
        }
      }`,
};

export const QUERY_LANGUAGES_KNOWN = {
  query: `query MyQuery {
        getLanguagesKnown {
            language
            lknID
            proficiency
            read
            speak
            write
            }
        }`,
};

export const QUERY_SAVEDJOBS = {
  query: `query MyQuery {
        getSavedJobs {
            description
            employmentType
            expMax
            expMin
            hospitalID
            isSalaryDisclosed
            lastDateToApply
            location
            maximumSalary
            minimumSalary
            name
            postedOn
            qualification
            savedJob
            vacancyID
            vacancyType
        }
    }`,
};
export const QUERY_DEPARTMENTS = {
  query: `query MyQuery {
        getDepartments {
            departmentID
            name
            }
    }`,
};

export const QUERY_GETCAREERPROFILE = {
  query: `query MyQuery {
        getCareerProfile {
            cpID
            departmentName
            desiredEmploymentType
            desiredJobType
            desiredShift
            emailOpted
            smsOpted
            whatsappOpted
            expectedSalaryEnd
            expectedSalaryStart
            industryID
            industryName
            isOtherIndustry
            isOtherRoleCategory
            phoneOpted
            roleCategoryID
            roleCategoryName
            isAnywhereFromIndia
        }
    }`,
};

export const GET_PREFERRED_LOCATION = {
  query: `query MyQuery {
      getPreferredWorkLocation {
        cityWithState
        locationID
        pwlID
      }
    }
  `,
  variables: null,
  operationName: 'MyQuery',
};

export const QUERY_GETCANDIDATEAVAILABILITY = {
  query: `query MyQuery {
        getCandidateAvailability {
            availID
            day
            fromTime
            toTime
        }
    }`,
};

export const QUERY_GETHOSPITAL = {
  query: `query MyQuery {
         getHospital {
            contactEmail
            contactName
            contactPhone
            hospitalID
            location
            name 
            shortName
            taxNumber
            type             
        }
    }`,
};

export const QUERY_SEARCHTOP4JOBS = {
  query: `query MyQuery {
        searchTop4Jobs {
            description
            experience
            employmentType
            hospitalID
            jobTitle
            lastDateToApply
            location
            maximumSalary
            minimumSalary
            name
            postedOn
            qualification
            savedJob
            vacancyID
            vacancyType     
        }
    }`,
};

export const QUERY_GETJOBALERT = {
  query: ` query MyQuery{
        getAlerts{
            alertName
            education
            exp
            hospitals
            jobType
            jsaID
            keyword
            location
            locationTop
            maximumSalary
            minimumSalary
            profession
            specialization
        }
    }`,
};

export const QUERY_NOTIFICATION = {
  query: `query MyQuery {
        getNotificationSettings {
          communicationEmail
          jobApplied
          newJobAlert
          newRecommendedJob
          profileStrength
          profilePicture
          updateProfile
          uploadResume
          userID
          communicationPush
          jobAppliedPush
          newJobAlertPush
          newRecommendedJobPush
          uploadResumePush
          profileStrengthPush
          profilePicturePush
          updateProfilePush
        }
      }`,
};

export const QUERY_GETINDUSTRY = {
  query: ` query MyQuery{
        getIndustry {
            healthcareIndustry
        }
    }`,
};

export const QUERY_GET_PROFILESTRENGTH = {
  query: `query MyQuery {
        getProfileStrength {
        strength
        }
    }`,
};

export const QUERY_GET_NOTIFICATION = {
  query: `query MyQuery {
        getNotifications {
          description
          nID
          status
          title
          createdAt
    }
  }`,
};

export const QUERY_GET_UNREAD_NOTIFICATION = {
  query: `query MyQuery {
      getNotificationCount {
        unreadCount
      }
    } 
  `,
};

export const QUERY_ADD_LOGIN_ACTIVITY = {
  query: `mutation MyMutation {
        addLoginActivity
      }
      `,
};

export const GET_STATE_MASTER = {
  query: `query MyQuery {
        getStateMaster(country: "INDIA") {
          state
      }
    }`,
  variables: null,
  operationName: 'MyQuery',
};

export const QUERY_GET_TRENDING_JOBS = {
  query: `query MyQuery {
        getTrendingJobs {
          announcedDate
          description
          employmentType
          expMax
          expMin
          hospitalID
          isSalaryDisclosed
          lastDateToApply
          location
          maximumSalary
          minimumSalary
          name
          numberOfVacancies
          postedOn
          qualification
          vacancyID
          vacancyType
          logo
        }
      }`,
  variables: null,
  operationName: 'MyQuery',
};

export const QUERY_GET_APPLIED_JOBS = {
  query: `query MyQuery {
        getAppliedJobs {
            description
            employmentType
            expMax
            expMin
            hospitalID
            isSalaryDisclosed
            lastDateToApply
            location
            maximumSalary
            minimumSalary
            name
            postedOn
            qualification
            savedJob
            vacancyID
            vacancyType
        }
      }
      `,
};

export const refreshJwtToken = async () => {
  const refresh_token = localStorage.getItem('refreshToken');
  // let refresh_token = res["refreshToken"]["token"];

  if (refresh_token?.length) {
    try {
      const res = await Auth.currentSession();
      let access_token = res['accessToken']['jwtToken'];
      let idToken = res['idToken']['jwtToken'];
      localStorage.setItem('accessToken', access_token);
      localStorage.setItem('idToken', idToken);
      // localStorage.setItem("refreshToken", refresh_token);
      return access_token;
    } catch {
      localStorage.clear();
      window.location.href = '/jobseeker-login';
    }
  } else {
    if (window.confirm('Something went wrong! \r\nPlease login to continue.')) {
      window.location.href = '/jobseeker-login';
      localStorage.clear();
    } else {
      window.location.href = '/';
      localStorage.clear();
    }
  }
};

export const gqlquery = async (query, variables) => {
  const gquery = {
    query: query.query,
    variables: variables,
  };
  const access_token = localStorage.getItem('accessToken');
  let final_token;

  try {
    jwt_decode(access_token, { header: true });
    let decoded = jwt_decode(access_token);

    if (decoded.exp < (new Date().getTime() + 1) / 1000) {
      final_token = await refreshJwtToken();
    } else {
      final_token = access_token;
    }

    const response = await fetch(
      `${process.env.REACT_APP_DOCTORS_FLOW_GRAPHQL_MAIN_URL}`,
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          authority: `${process.env.REACT_APP_DOCTORS_FLOW_AUTHORITYL}`,
          authorization: final_token,
          referer: `${process.env.REACT_APP_DOCTORS_FLOW_REFERER}`,
          'accept-language': 'en-US,en;q=0.9',
        },
        body: JSON.stringify(gquery),
      }
    );

    const clonedResponse = response.clone();
    const responseData = await clonedResponse.json();
    if (responseData.errors) {
      const { errors } = responseData;
      saveErrorToDB(errors, query.query);
    }
    return response;
  } catch (error) {
    let refresh_token = localStorage.getItem('refreshToken');

    if (refresh_token) {
      final_token = await refreshJwtToken();
    }

    const response = await fetch(
      `${process.env.REACT_APP_DOCTORS_FLOW_GRAPHQL_MAIN_URL}`,
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          authority: `${process.env.REACT_APP_DOCTORS_FLOW_AUTHORITYL}`,
          authorization: final_token,
          referer: `${process.env.REACT_APP_DOCTORS_FLOW_REFERER}`,
          'accept-language': 'en-US,en;q=0.9',
        },
        body: JSON.stringify(gquery),
      }
    );
    const clonedResponse = response.clone();
    const responseData = await clonedResponse.json();
    if (responseData.errors) {
      const { errors } = responseData;
      saveErrorToDB(errors, query.query);
    }
    return response;
  }
};

export const gqlOpenQuery = async (query, variables) => {
  try {
    const gquery = {
      query: query.query,
      variables: variables,
    };

    const response = await fetch(
      `${process.env.REACT_APP_FAQ_FLOW_GRAPHQL_MAIN_URL}`,
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          authority: `${process.env.REACT_APP_FAQ_FLOW_AUTHORITYL}`,
          referer: 'https://ap-south-1.console.aws.amazon.com',
          'accept-language': 'en-US,en;q=0.9',
          'x-api-key': `${process.env.REACT_APP_FAQ_FLOW_X_API_KEY}`,
          'X-Amz-User-Agent': `${process.env.REACT_APP_FAQ_FLOW_USER_AGENT}`,
        },
        body: JSON.stringify(gquery),
      }
    );

    const clonedResponse = response.clone();
    const responseData = await clonedResponse.json();
    if (responseData.errors) {
      const { errors } = responseData;
      saveErrorToDB(errors, query.query);
    }
    return response;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const wordpressEndpoint = async (endpoint) => {
  return new Promise((resolve, reject) => {
    try {
      fetch(`${process.env.REACT_APP_WORDPRESS_URL}`, {
        method: 'POST',
        body: JSON.stringify(endpoint),
      }).then(async (response) => {
        const clonedResponse = response.clone();
        const responseData = await clonedResponse.json();
         resolve(responseData);
      });
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
