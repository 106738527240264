import { Amplify, Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import {
  gqlquery as hospitalquery,
  QUERY_GETHOSPITAL,
  QUERY_GETMYPROFILE,
  QUERY_GET_RECRUITER_UNREAD_NOTIFICATION,
  QUERY_HOSPITALDETAILS,
  refreshHospitalJwtToken,
  QUERY_GET_RECRUITER_UNREAD_REMINDERS
} from "../api/hospitalIndex";
import { gqlquery, QUERY_GET_PROFILESTRENGTH, QUERY_LISTPROFILES, QUERY_NOTIFICATION, QUERY_GET_CARD_ELIGIBLE_STATUS, QUERY_GET_UNREAD_NOTIFICATION, QUERY_GETEXPERIENCELIST, QUERY_GETEDUCATION, gqlOpenQuery } from "../api/index";
import Subscriptions from "../components/Subscriptions/Subscriptions";

const useUserState = () => {
  const [user, setUser] = useState({});
  const [jobSearch, setJobSearch] = useState({
    jobTitle: '',
    location: ''
  })
  const [resumeSearch, setResumeSearch] = useState({
    anyKeywords: ''
  })
  const [permitUser, setPermitUser] = useState({});
  const [hospitalUser, setHospitalUser] = useState({});
  const [getHospitalDetailsData, setGetHospitalDetailsData] = useState({});
  const [notifications, setNotifications] = useState([]);
  const [reminders, setReminders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [base64Image, setBase64ProfileImage] = useState();
  const [hospitalImage, setHospitalImage] = useState();
  const [userName, setUserName] = useState("");
  const [profileStrength, setProfileStrength] = useState(0);
  const [userEmail, setUserEmail] = useState("");
  const [pubNotifications, setPubNotifications] = useState({});
  const [openDrawer, setOpenDrawer] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [recruiterModalOpen, setRecruiterModalOpen] = useState(false)
  const [userNotLogIn, setUserNotLogIn] = useState(false);
  const [filterFlag, setFilterFlag] = useState(false);
  const [isCreatejobAlertBtnClicked, setIsCreatejobAlertBtnClicked] = useState(false);
  const [getCardEligibleStatus, setGetCardEligibleStatus] = useState({});
  const [isVirtualCard, setIsVirtualCard] = useState(false);
  const [loadingVirtualCard, setLoadingVirtualCard] = useState(false);
  const [verificationUpdated, setVerificationUpdated] = useState(false);
  const [educationDetails, setEducationDetails] = useState([]);
  const [getExperiencedList, setGetExperiencedList] = useState([]);
  const [topHospitals, setTopHospitals] = useState();
  const [showKycTag, setShowKycTag] = useState(false)
  const [completeKYCPopUp, setCompleteKYCPopUp] = useState(false);
  const [langAccordToState, setLangAccordToState] = useState({})
  const [autoSuggestionsLang, setAutoSuggestionsLang] = useState(true);
  const deviceTokenJS = localStorage.getItem("deviceToken");
  const deviceTokenRecruiter = localStorage.getItem("deviceTokenRecruiter");
  const [profileLocation, setProfileLocation] = useState("")
  const [pageHistory, setPageHistory] = useState([]);
  const [logOutLoading, setLogOutLoading] = useState(false);
  const [headerHeroTheme, setHeaderHeroTheme] = useState("dark");
  const [NavigateHistory, setNavigateHistory] = useState([])
  const [currentUser, setCurrentUser] = useState(true)

  useEffect(() => {
    if (![NavigateHistory[0], '/recruiter-home', '/recruiters-login', '/', '/jobseeker-login', '/create-profile'].includes(window.location.pathname))
      setNavigateHistory([window.location.pathname, ...NavigateHistory])
  }, [window.location.href])

  if (localStorage.getItem("flow") === 'jobSeeker') {
    Amplify.configure({
      Auth: {
        region: "ap-south-1",
        userPoolId: `${process.env.REACT_APP_DOCTORS_FLOW_SIGNUP_USERPOOLID}`,
        userPoolWebClientId: `${process.env.REACT_APP_DOCTORS_FLOW_SIGNUP_CLIENTID}`,
      },
    });
  }
  if (localStorage.getItem("flow") === 'hospital') {
    Amplify.configure({
      Auth: {
        region: "ap-south-1",
        userPoolId: `${process.env.REACT_APP_HOSPITAL_FLOW_SIGNUP_USERPOOLID}`,
        userPoolWebClientId: `${process.env.REACT_APP_HOSPITAL_FLOW_SIGNUP_CLIENTID}`,
      },
    });
  }
  const getUserEmail = async () => {
    if (localStorage.getItem("accessToken")) {
      try {
        const userInfo = await Auth.currentUserInfo()
        const userEmail = userInfo.attributes.email
        setUserEmail(userEmail);
        return userEmail;
      } catch (err) {
        if (localStorage.getItem("flow") === "hospital") {
          refreshHospitalJwtToken();
        }
      }
    } else {
      setUserEmail("");
    }
  };

  useEffect(() => {
    getUserEmail();
    setHeaderHeroTheme("dark")
  }, [])


  // useEffect(() => {
  //   setInterval(() => {
  //   }, `${Number(process.env.REACT_APP_DOCTORS_FLOW_REFRESH_TOKEN_TIME_INTERVAL)}`);
  // });

  const getProfileInformation = async () => {
    const res = await gqlquery(QUERY_LISTPROFILES, null)
    const result = await res.json();
    setUser(result?.data?.getProfile);
    return result;
  };

  const getProfilePicture = async () => {
    const profile = await getProfileInformation();
    const QUERY_DOWNLOADPROFILEPIC = {
      query: `query MyQuery {
        downloadDocument (url: "${profile?.data?.getProfile?.profilePicURL}")
      }`,
    };

    return await gqlquery(QUERY_DOWNLOADPROFILEPIC, null)
      .then((res) => res.json())
      .then((datas) => {
        if (datas?.data?.downloadDocument) {
          const downloadDocument = JSON?.parse(datas?.data?.downloadDocument);
          const imageSource = `data:image/png;base64,${downloadDocument?.response?.content}`;
          setBase64ProfileImage(imageSource);
          return { profilePicture: imageSource };
        } else {
          setBase64ProfileImage("data:image/png;base64,");
          return { profilePicture: "data:image/png;base64," };
        }
      });
  }

  const hospitalTokenCheckers = async () => {
    const res = await gqlquery(QUERY_HOSPITALDETAILS, null)
    const result = await res.json();
    return result;
  };

  const getUserProfile = async () => {
    if (localStorage.getItem("flow") === "jobSeeker") {
      await gqlquery(QUERY_LISTPROFILES, null)
        .then((res) => res.json())
        .then((datas) => {
          if (datas?.data?.getProfile?.name) {
            setUser(datas?.data?.getProfile);
          }
          setIsLoading(false);
          const QUERY_DOWNLOADPROFILEPIC = {
            query: `query MyQuery {
              downloadDocument (url: "${datas?.data?.getProfile?.profilePicURL}")
            }`,
          };
          gqlquery(QUERY_DOWNLOADPROFILEPIC, null)
            .then((res) => res.json())
            .then((datas) => {
              if (datas?.data?.downloadDocument) {
                const downloadDocument = JSON?.parse(datas?.data?.downloadDocument);
                const imageSource = `data:image/png;base64,${downloadDocument?.response?.content}`;
                setBase64ProfileImage(imageSource);
              } else {
                setBase64ProfileImage("data:image/png;base64,");
              }
            });
        });
    };

    if (localStorage.getItem("flow") === "hospital") {
      await hospitalquery(QUERY_HOSPITALDETAILS, null)
        .then((res) => res.json())
        .then((data) => {
          setGetHospitalDetailsData(data?.data?.getHospitalDetails);
          const QUERY_DOWNLOADRESUME = {
            query: `query MyQuery {
                downloadDocument (url: "${data?.data?.getHospitalDetails?.profilePicURL}")
              }`,
          };
          hospitalquery(QUERY_DOWNLOADRESUME, null)
            .then((res) => res.json())
            .then((datas) => {
              if (datas?.data?.downloadDocument) {
                const downloadDocument = JSON?.parse(datas?.data?.downloadDocument);
                const imageSource = `data:image/png;base64,${downloadDocument?.response?.content}`;
                setHospitalImage(imageSource);
              } else {
                setHospitalImage("data:image/png;base64,");
              }
            });
        });

      await hospitalquery(QUERY_GETHOSPITAL, null)
        .then((res) => res.json())
        .then((data) => {
          if (data?.data?.getHospital?.contactEmail) {
            setHospitalUser(data?.data?.getHospital);
          }
        });
    }
  };

  const getNotifications = () => {
    if (localStorage.getItem("flow") === "jobSeeker") {
      gqlquery(QUERY_GET_UNREAD_NOTIFICATION, null)
        .then((res) => res.json())
        .then((data) => setNotifications(data?.data?.getNotificationCount?.unreadCount));
    } else if (localStorage.getItem("flow") === "hospital") {
      hospitalquery(QUERY_GET_RECRUITER_UNREAD_NOTIFICATION, null)
        .then((res) => res.json())
        .then((data) => setNotifications(data?.data?.getRecruiterNotificationCount?.unreadCount));
    }
  };

  const getReminders = () => {
    if (localStorage.getItem("flow") === "hospital") {
      hospitalquery(QUERY_GET_RECRUITER_UNREAD_REMINDERS, null)
        .then((res) => res.json())
        .then((data) => {
          setReminders(data?.data?.getReminderCount?.totalReminder);
        });
    }
  };

  const handleRemoveJSLogin = (deviceTokenJS) => {
    return new Promise(async (resolve, reject) => {
      const MUTATION_REMOVE_JS_DEVICE_TOKEN = {
        query: `mutation MyMutation {
        removeJobSeekerDeviceToken(deviceToken: "${deviceTokenJS}")
      }`,
        variables: null,
        operationName: "MyMutation",
      };

      await gqlquery(MUTATION_REMOVE_JS_DEVICE_TOKEN, null)
        .then((res) => res.json())
        .then((data) => resolve(data))
        .catch((err) => reject(err))
    })
  }

  const handleRemoveRecruiterLogin = async () => {
    return new Promise(async (resolve, reject) => {
      const MUTATION_REMOVE_RECRUITER_DEVICE_TOKEN = {
        query: `mutation MyMutation {
        removeRecruiterDeviceToken(deviceToken: "${deviceTokenRecruiter}")
      }`,
        variables: null,
        operationName: "MyMutation",
      };

      await hospitalquery(MUTATION_REMOVE_RECRUITER_DEVICE_TOKEN, null)
        .then((res) => res.json())
        .then((data) => resolve(data))
        .catch((err) => reject(err))
    })
  }


  const logOut = async (deviceTokenJS) => {
    setLogOutLoading(true)
    return new Promise(async (resolve, reject) => {
      handleRemoveJSLogin(deviceTokenJS);
      try {
        await Auth.signOut()
        setUser(null);
        setLogOutLoading(false)
        resolve()
      } catch (err) {
        setLogOutLoading(false)
        reject(err)
      }
    })
  };

  const logOutHospital = async () => {
    setLogOutLoading(true)
    return new Promise(async (resolve, reject) => {
      handleRemoveRecruiterLogin();
      try {
        await Auth.signOut()
        setHospitalUser(null);
        setLogOutLoading(false)
        resolve()
      } catch (err) {
        setLogOutLoading(false)
        reject(err)
      }
    })
  };

  const getUserRole = () => {
    setIsLoading(true);
    if (localStorage.getItem("flow") === "hospital") {
      hospitalquery(QUERY_GETMYPROFILE, null)
        .then((res) => res.json())
        .then((data) => setPermitUser(data?.data?.getMyProfile))
        .finally(() => setIsLoading(false));
    } else setIsLoading(false);
  };

  const cardEligibleStatus = () => {
    gqlquery(QUERY_GET_CARD_ELIGIBLE_STATUS, null)
      .then((res) => res.json())
      .then((data) => setGetCardEligibleStatus(data?.data?.getCardEligibleStatus))
      .finally(() => {
        setIsLoading(false);
        setTimeout(() => {
          setLoadingVirtualCard(false);
        }, 3000);
      });
  };

  const getEducationListDetails = () => {
    gqlquery(QUERY_GETEDUCATION, null)
      .then((res) => res.json())
      .then((datas) => {
        setEducationDetails(datas.data?.getEducationList);
      });
  };

  const getExperienceListDetails = () => {
    gqlquery(QUERY_GETEXPERIENCELIST, null)
      .then((res) => res.json())
      .then((datas) => {
        setGetExperiencedList(datas?.data?.getExperienceList);
      });
  };

  const getUserName = () => {
    setIsLoading(true);
    if (localStorage.getItem("flow") === "jobSeeker") {
      gqlquery(QUERY_LISTPROFILES, null)
        .then((res) => res.json())
        .then(datas => {
          setUser(datas?.data?.getProfile);
          setUserName(datas?.data?.getProfile?.name);
          setIsLoading(false);
        })
        .finally(() => setIsLoading(false));
    } else setIsLoading(false);
  };

  const handleStrengthUpdate = () => {
    gqlquery(QUERY_GET_PROFILESTRENGTH, null)
      .then((res) => res.json())
      .then((data) => {
        setProfileStrength(data.data.getProfileStrength);
      })
  };

  const handleEmailAddressVerification = async () => {
    const userInfo = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(userInfo, {
      'email': `${user?.email}`,
    });
    setVerificationUpdated(pre => !pre);
  };

  const handlePhoneNumberVerification = async () => {
    const userInfo = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(userInfo, {
      'phone_number': `+91${user?.phone}`,
    });
    setVerificationUpdated(pre => !pre);
  };

  const fetchNotificatios = () => {
    if (localStorage.getItem("flow") === "jobSeeker") {
      gqlquery(QUERY_NOTIFICATION, null)
        .then((res) => res.json())
        .then((data) => setPubNotifications(data?.data?.getNotificationSettings)
        )
    }
  };

  Subscriptions({ getNotifications, notifications, permitUser, getReminders, reminders, getHospitalDetailsData, getUserProfile, currentUser });

  const getHospitalsInUsersLocation = () => {
    const state = localStorage.getItem("state") || ""
    const city = localStorage.getItem("city") || ""
    const QUERY_GET_TOP_HOSPPITALS = {
      query: `query MyQuery {
            getTopHospitalsInUserLocation(city: "${city}", state: "${state}") {
              city
              hospitalName
              imageURL
              state
            }
          }`
    };
    gqlOpenQuery(QUERY_GET_TOP_HOSPPITALS, null)
      .then((res) => res.json())
      .then((data) => {
        setTopHospitals(data?.data?.getTopHospitalsInUserLocation)
      });
  }

  return {
    getHospitalsInUsersLocation,
    topHospitals,
    getUserProfile,
    getUserEmail,
    userEmail,
    hospitalUser,
    getHospitalDetailsData,
    user,
    getUserRole,
    isLoading,
    setIsLoading,
    permitUser,
    logOut,
    base64Image,
    hospitalImage,
    logOutHospital,
    getNotifications,
    getReminders,
    notifications,
    reminders,
    setHospitalImage,
    getProfileInformation,
    getProfilePicture,
    hospitalTokenCheckers,
    getUserName,
    userName,
    handleStrengthUpdate,
    profileStrength,
    setBase64ProfileImage,
    setJobSearch,
    jobSearch,
    setResumeSearch,
    resumeSearch,
    pubNotifications,
    setPubNotifications,
    fetchNotificatios,
    openDrawer,
    setOpenDrawer,
    modalOpen,
    setModalOpen,
    recruiterModalOpen,
    setRecruiterModalOpen,
    userNotLogIn,
    setUserNotLogIn,
    filterFlag,
    setFilterFlag,
    isCreatejobAlertBtnClicked,
    setIsCreatejobAlertBtnClicked,
    getCardEligibleStatus,
    cardEligibleStatus,
    isVirtualCard,
    setIsVirtualCard,
    setLoadingVirtualCard,
    loadingVirtualCard,
    getEducationListDetails,
    getExperienceListDetails,
    educationDetails,
    getExperiencedList,
    handleRemoveJSLogin,
    handleRemoveRecruiterLogin,
    handleEmailAddressVerification,
    handlePhoneNumberVerification,
    verificationUpdated,
    setVerificationUpdated,
    completeKYCPopUp,
    setCompleteKYCPopUp,
    setShowKycTag,
    showKycTag,
    profileLocation,
    setProfileLocation,
    langAccordToState,
    setLangAccordToState,
    autoSuggestionsLang,
    setAutoSuggestionsLang,
    pageHistory,
    logOutLoading,
    headerHeroTheme,
    setHeaderHeroTheme,
    setNavigateHistory,
    NavigateHistory,
    setCurrentUser,
    currentUser
  };
};

export default useUserState;
