import React, { Suspense, useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Hub } from 'aws-amplify';
import { ErrorBoundary } from 'react-error-boundary';
import { Header, FooterLandingPage, FallBack, BackdropLoader } from "./components";
import { Signup, Signup2, Login, Profile, UsersList, JobsAndResponses, RegisterHospital, HospitalLogin, CreateVacancy, PostJob, HospitalDashboard, ApplicantDetails, ToastTitle, ManageJobsAndResponses, JobTitle, SavedSearches, PersonalFolders, Notifications, Reminders, SubscriptionStatus, CompanyProfile, AdvanceSearch, SearchResumeResult, AccountDetails, PricingPlans, Checkout, JobSearchList, SingleJob, ForgotPassword, ContactUs, BasicDetails, ProfileHome, SavedJobs, AccountSettings, ProfileListFolder, EditBasicDetails, PrimaryHomeContainer, FAQ, ManageAlert, AboutUs, TermAndUsage, PrivacyPolicy, PaymentStatus, JobseekerFeature, JobSeekersSearchJobs, RecruiterFeature, CreateYourProfile, JobseekerEducationInfo, JobseekerExperienceInfo, UploadResumeInfo, FeatureComingSoon, RefundCancellationPolicy, HowToPurchase, Team, PressAndMedia, AddBasicDetails, Blogs, BlogDetails, PostPremiumJobMedLinkSystemUser, JobseekerNotifications, HospitalSignUp, FeaturedHospital, LandingPage, AccountSettingJR, JobPostPricing, AppliedJobs, RecruiterFeaturesPostAJob, RecruiterFeaturesBrandingSolutions, RecruiterFeaturesSearchCandidate, RecruitersStaffingSolutions, RecruiterFeaturesSearchCandidateForm, RecruitersStaffingSolutionsForm, RecruiterFeaturesBrandingSolutionsForm, RecruiterFeaturesFormSubmissionSuccess, CAMPAIGNHOMEPAGE, DownloadApp, RecruiterHome, Courses, SavedJobSeekerProfile, JobRole } from "./pages";
import ROUTES from "./routes";
import "./App.css";
import SetNewPassword from "./components/SignupForm/SetNewPassword";
import Notification from "./services/PushNotifiations";
import useApplicationState from "./hooks/useApplicationState";
import { gqlquery, QUERY_ADD_LOGIN_ACTIVITY } from "./api";
import { gqlquery as hospitalQuery } from "./api/hospitalIndex";
import { AllPagePixelCode } from "./components/CommonSmallComponents/CommonSmallComponents";
import { preloadErrorImage } from "./components/Utility/CommonFunctions";
import errorScreen from "./assets/images/errorScreen.png";

function App() {
  const { pathname } = useLocation();
  const { loadingVirtualCard, logOutLoading } = useApplicationState();
  const [renderPage, setRenderPage] = useState(false);

  let addLoginActivity = () => {
    setTimeout(() => {
      if (localStorage.getItem("flow") === "hospital") {
        hospitalQuery(QUERY_ADD_LOGIN_ACTIVITY, null)
          .then((res) => res.json())
          .then((datas) => { });
        return
      } else {
        gqlquery(QUERY_ADD_LOGIN_ACTIVITY, null)
          .then((res) => res.json())
          .then((datas) => { });
        return
      }
    }, 5 * 1000)
  }

  useEffect(() => {
    Hub.listen('auth', ({ payload }) => {
      const { event } = payload;
      switch (event) {
        case 'tokenRefresh':
          addLoginActivity()
          break;
        case 'signIn':
          document.cookie = 'showBanner=true ;  path=/';
          document.cookie = 'showinformation=true ; path=/';
          addLoginActivity()
        default:
      }
    });
  }, []);

  useEffect(() => {
    fetch("https://ipapi.co/json/").then((res) => res.json()).then((data) => {
      localStorage.setItem("state", data.region)
    }).catch((err) => { })

    preloadErrorImage(errorScreen);
  }, [])

  useEffect(() => {
    // do not remove below setRenderPage function. it's required for rendering AllPagePixelCode correctly on route change
    setRenderPage(pre => !pre)
  }, [pathname])

  const handleError = (error, errorInfo) => { }

  return (
    <Suspense fallback={""}>
      <ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
        <AllPagePixelCode />
        <Notification />
        {((pathname === "/profile-home" || pathname === "/profile") && loadingVirtualCard === true) ? (
          <></>
        ) : (<Header />)}
        <Routes>
          <Route exact path="/" element={<PrimaryHomeContainer />} />
          <Route exact path={ROUTES.CAMPAIGNHOMEPAGE} element={<CAMPAIGNHOMEPAGE />} />
          <Route exact path={ROUTES.SIGNUP} element={<Signup />} />
          <Route exact path={ROUTES.CREATE_NEW_PASSWORD} element={<SetNewPassword />} />
          <Route exact path={ROUTES.SIGNUP2} element={<Signup2 />} />
          <Route exact path={ROUTES.PROFILE} element={<Profile />} />
          <Route exact path={ROUTES.LOGIN} element={<Login />} />
          <Route exact path={ROUTES.USERLIST} element={<UsersList />} />
          <Route exact path={ROUTES.POSTPRIVATEJOB} element={<JobsAndResponses />} />
          <Route exact path={ROUTES.MANAGEJOBSANDRESPONSES} element={<ManageJobsAndResponses />} />
          <Route exact path={ROUTES.CREATEVACANCIES} element={<CreateVacancy />} />
          <Route exact path={ROUTES.POSTJOB} element={<PostJob />} />
          <Route exact path={ROUTES.JOBTITLE} element={<JobTitle />} />
          <Route exact path={ROUTES.CREDITEXPIRED} element={<ToastTitle />} />
          <Route exact path={ROUTES.REGISTERHOSPITAL} element={<RegisterHospital />} />
          <Route exact path={ROUTES.HOSPITALSIGNUP} element={<HospitalSignUp />} />
          <Route exact path={ROUTES.HOSPITALSIGNUP1} element={<HospitalSignUp />} />
          <Route exact path={ROUTES.HOSPITALLOGIN} element={<HospitalLogin />} />
          <Route exact path={ROUTES.HOSPITALDASHBOARD} element={<HospitalDashboard />} />
          <Route exact path={ROUTES.APPLICANTDETAILS} element={<ApplicantDetails />} />
          <Route exact path={ROUTES.APPLICANTDETAILS2} element={<ApplicantDetails />} />
          <Route exact path={ROUTES.SAVEDSEARCHES} element={<SavedSearches />} />
          <Route exact path={ROUTES.PERSONALFOLDERS} element={<PersonalFolders />} />
          <Route exact path={ROUTES.NOTIFICATIONS} element={<Notifications />} />
          <Route exact path={ROUTES.REMINDERS} element={<Reminders />} />
          <Route exact path={ROUTES.SUBSCRIPTIONSTATUS} element={<SubscriptionStatus />} />
          <Route exact path={ROUTES.COMPANYPROFILE} element={<CompanyProfile />} />
          <Route exact path={ROUTES.ADVANCESEARCH} element={<AdvanceSearch />} />
          <Route exact path={ROUTES.SEARCHRESUMERESULT} element={<SearchResumeResult />} />
          <Route exact path={ROUTES.ACCOUNTDETAILS} element={<AccountDetails />} />
          <Route exact path={ROUTES.PRICINGPLANS} element={<PricingPlans />} />
          <Route exact path={ROUTES.CHECKOUTPLAN} element={<Checkout />} />
          <Route exact path={ROUTES.JOBSEARCHLIST} element={<JobSearchList />} />
          <Route exact path={ROUTES.SINGLEJOBSEARCH} element={<SingleJob />} />
          <Route exact path={ROUTES.FORGOTPASSWORD} element={<ForgotPassword />} />
          <Route exact path={ROUTES.CONTACTUS} element={<ContactUs />} />
          <Route exact path={ROUTES.BASICDETAILS} element={<BasicDetails />} />
          <Route exact path={ROUTES.PROFILEHOME} element={<ProfileHome />} />
          <Route exact path={ROUTES.SAVEDJOBS} element={<SavedJobs />} />
          <Route exact path={ROUTES.ACCOUNTSETTINGS} element={<AccountSettings />} />
          <Route exact path={ROUTES.RECRUITERACCOUTSETTING} element={<AccountSettingJR />} />
          <Route exact path={ROUTES.PROFILELISTFOLDER} element={<ProfileListFolder />} />
          <Route exact path={ROUTES.FEATUREDHOSPITAL} element={<FeaturedHospital />} />
          <Route exact path={ROUTES.EDITBASICDETAILS} element={<EditBasicDetails />} />
          <Route exact path={ROUTES.PRIMARYHOME} element={<PrimaryHomeContainer />} />
          <Route exact path={ROUTES.FAQ} element={<FAQ />} />
          <Route exact path={ROUTES.MANAGEALERT} element={<ManageAlert />} />
          <Route exact path={ROUTES.ABOUTUS} element={<AboutUs />} />
          <Route exact path={ROUTES.TERMSANDUSAGE} element={<TermAndUsage />} />
          <Route exact path={ROUTES.PRIVACYPOLICY} element={<PrivacyPolicy />} />
          <Route exact path={ROUTES.PAYMENTSTATUS} element={<PaymentStatus />} />
          <Route exact path={ROUTES.JOBSEEKERFEATURE} element={<JobseekerFeature />} />
          <Route exact path={ROUTES.JOBSEEKERSSEARCHJOBS} element={<JobSeekersSearchJobs />} />
          <Route exact path={ROUTES.RECRUITERFEATURE} element={<RecruiterFeature />} />
          <Route exact path={ROUTES.CREATEPROFILE} element={<CreateYourProfile />} />
          <Route exact path={ROUTES.REGISTEREDUCATION} element={<JobseekerEducationInfo />} />
          <Route exact path={ROUTES.REGISTEREXPERIENCE} element={<JobseekerExperienceInfo />} />
          <Route exact path={ROUTES.REGISTERPREFERENCE} element={<UploadResumeInfo />} />
          <Route exact path={ROUTES.FEATURECOMINGSOON} element={<FeatureComingSoon />} />
          <Route exact path={ROUTES.REFUNDCANCELLATIONPOLICY} element={<RefundCancellationPolicy />} />
          <Route exact path={ROUTES.HOWTOPURCHASE} element={<HowToPurchase />} />
          <Route exact path={ROUTES.TEAM} element={<Team />} />
          <Route exact path={ROUTES.PRESSANDMEDIA} element={<PressAndMedia />} />
          <Route exact path={ROUTES.ADDBASICDETAILS} element={<AddBasicDetails />} />
          <Route exact path={ROUTES.POSTPREMIUMJOBMEDLINKSYSTEMUSER} element={<PostPremiumJobMedLinkSystemUser />} />
          <Route exact path={ROUTES.BLOGS} element={<Blogs />} />
          <Route exact path={ROUTES.BLOG} element={<Blogs />} />
          <Route exact path={ROUTES.BLOGDETAILS} element={<BlogDetails />} />
          <Route exact path={ROUTES.JOBSEEKERNOTIFICATIONS} element={<JobseekerNotifications />} />
          <Route exact path={ROUTES.LANDINGPAGE} element={<LandingPage />} />
          <Route exact path={ROUTES.JOBPOSTPRICING} element={<JobPostPricing />} />
          <Route exact path={ROUTES.APPLIEDJOBS} element={<AppliedJobs />} />
          <Route exact path={ROUTES.RECRUITERFEATURESPOSTAJOB} element={<RecruiterFeaturesPostAJob />} />
          <Route exact path={ROUTES.RECRUITERFEATURESBRANDINGSOLUTIONS} element={<RecruiterFeaturesBrandingSolutions />} />
          <Route exact path={ROUTES.RECRUITERFEATURESSEARCHCANDIDATE} element={<RecruiterFeaturesSearchCandidate />} />
          <Route exact path={ROUTES.RECRUITERSSTAFFINGSOLUTIONS} element={<RecruitersStaffingSolutions />} />
          <Route exact path={ROUTES.RECRUITERFEATURESSEARCHCANDIDATEFORM} element={<RecruiterFeaturesSearchCandidateForm />} />
          <Route exact path={ROUTES.RECRUITERSSTAFFINGSOLUTIONSFORM} element={<RecruitersStaffingSolutionsForm />} />
          <Route exact path={ROUTES.RECRUITERFEATURESBRANDINGSOLUTIONSFORMS} element={<RecruiterFeaturesBrandingSolutionsForm />} />
          <Route exact path={ROUTES.RECRUITERFEATURESFORMSUBMISSIONSUCCESS} element={<RecruiterFeaturesFormSubmissionSuccess />} />
          <Route exact path={ROUTES.DownloadApp} element={<DownloadApp />} />
          <Route exact path={ROUTES.RECRUITERHOME} element={<RecruiterHome />} />
          <Route exact path={ROUTES.COURSE} element={<Courses />} />
          <Route exact path={ROUTES.SAVEDJOBSEEKERPROFILE} element={<SavedJobSeekerProfile />} />
          <Route exact path={ROUTES.JOBROLE} element={<JobRole />} />
        </Routes>
        {((pathname === "/profile-home" || pathname === "/profile") && loadingVirtualCard === true) ? (
          <></>
        ) : (<FooterLandingPage />)}
        <BackdropLoader loading={logOutLoading} />
      </ErrorBoundary>
    </Suspense>
  );
}

export default App;